import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Globals } from "../globals";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  topOfferingTemp = [];
  constructor(private http: HttpClient, private globals: Globals) {}
  getUsersByPage() {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/adminadvanced/user`
    );
  }
  getUserOrders(id) {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/adminadvanced/user-orders/${id}`
    );
  }
  getUserProfile(id) {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/adminadvanced//user-order-summary/${id}`
    );
  }
  getPopularOfferings() {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/adminadvanced/popular-offerings`
    );
  }
  getPopularVendors() {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/adminadvanced/popular-vendor`
    );
  }
  getPopularCategories() {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/adminadvanced/popular-categories`
    );
  }
  getVipUsers() {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/adminadvanced/vip-users`
    );
  }
}
