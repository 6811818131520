import { PromoFilterPipe } from './promo-filter';
import { ImageUrlPipe } from "./image-url";
import { OfferingFilterPipe } from "./offering-filter.pipe";
import { NgModule } from "@angular/core";

@NgModule({
  declarations: [OfferingFilterPipe, ImageUrlPipe, PromoFilterPipe],
  imports: [],
  exports: [OfferingFilterPipe, ImageUrlPipe, PromoFilterPipe],
  providers: [],
})
export class PipesModule {}
