import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class VendorOfferingsService {

  constructor(private http: HttpClient, private globals: Globals) {

  }

  getAll() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/vendors/admin`);
  }

  makeACopy(vendorId: string, offeringId: string) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/offerings/${offeringId}/copy/${vendorId}`, {});
  }

  get(id: string) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/vendors/${id}`);
  }

  add(vendorId: string, offering: any) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/vendors/${vendorId}/offerings`, offering);
  }

  update(vendor: any) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/vendors/${vendor._id}`, vendor);
  }

  delete(id: string) {
    return this.http.delete<any>(`${this.globals.config.apiUrl}/vendors/${id}`);
  }

  updateVendorOrdering(updateDetails) {
    //uvo: update vendor ordering
    return this.http.post<any>(`${this.globals.config.apiUrl}/vendors/uoo`, updateDetails)
  }
}
