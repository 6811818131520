import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class DeliveryAreasService {

  constructor(private http: HttpClient, private globals: Globals) {

  }

 getAll() {
   return this.http.get<any>(`${this.globals.config.apiUrl}/locations`);
 }

 add(category: any) {
   return this.http.post<any>(`${this.globals.config.apiUrl}/offerings/categories`, category);
 }

 update(id: string, category: any) {
   return this.http.put<any>(`${this.globals.config.apiUrl}/offerings/categories/${id}`, category);
 }

 delete(id: string) {
   return this.http.delete<any>(`${this.globals.config.apiUrl}/offerings/categories/${id}`);
 }
}
