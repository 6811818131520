import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "promoFilter",
})
export class PromoFilterPipe implements PipeTransform {
  transform(value: any, type: any) {
    if (value) {
      if (type === 1) {
        return value.filter((i) => !i.createdBy || i.createdBy === "admin");
      } else {
        return value.filter((i) => i.createdBy && i.createdBy !== "admin");
      }
    }
  }
}
