import { filter } from "rxjs/operators";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "offeringFilter",
})
export class OfferingFilterPipe implements PipeTransform {
  transform(value: any, catId: any): any {
    if (catId) {
      return value.filter((i) => {
        if (i.categoryId) {
          if (i.categoryId._id === catId) {
            return i;
          }
        }
      });
    } else {
      return value.filter((i) => {
        if (!i.categoryId) {
          return i;
        }
      });
    }
  }
}
