import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'imageUrl',
})
export class ImageUrlPipe implements PipeTransform {
  transform(value: any) {
    if (value.images.length) {
      return value.images[0].cdnUrl;
    } else {
      for (let a of value.serviceCategories) {
        if (value.vendorImages[a]) {
          if (value.vendorImages[a].length) {
            return value.vendorImages[a][0].cdnUrl;
          }
        }
      }
      return './assets/imgs/placeholder3.jpg';
    }
  }
}
