import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(private http: HttpClient, private globals: Globals) {

  }
  create(formData) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/filter`, formData);
  }

  getAll() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/filter`);
  }

  delete(id) {
    return this.http.delete<any>(`${this.globals.config.apiUrl}/filter/${id}`);
  }

  getOne(id) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/filter/${id}`);
  }

  update(id, value) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/filter/${id}`, value);
  }

  updateVendor(id, value) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/filter/vendor-update/${id}`, value);
  }
}
